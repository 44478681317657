import { defineMessages } from 'react-intl';

/**
 * Internationalized messages for use on homepage.
 */
export default defineMessages({
  testimonialsTitle: {
    id: 'home.testimonials.title',
    defaultMessage: 'Mapathon - For Us, For the Land, For our Future',
  },
  cmCitation: {
    id: 'home.testimonials.cm.citation',
    defaultMessage:
      "It was during the period of flood we realized the significance of location mapping. Mapathon Keralam is an initiative by the state government for addressing this. I request all Keralites who are interested in rebuilding Kerala to be a part of this joint initiative",
  },
  cmBio: {
    id: 'home.testimonials.cm.bio',
    defaultMessage: 'Hon. Chief Minister of Kerala',
  },
  cmName: {
    id: 'home.testimonials.cm.name',
    defaultMessage: 'Shri. Pinarayi Vijayan',
  },
});
