import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './messages';
import { Button } from '../button';

function contactCard() {
  return (
    <div className="w-25-l w-100 fl pv2 ph3 secondary shadow-4 bg-white">
      <h3 className="ma1 pb3 w-70-l w-60-m f3 fw8 ttu barlow-condensed">
        <FormattedMessage {...messages.organizationContactTitle} />
      </h3>
      <div className="tc cf mb2">
        <p>
          <a rel="noopener noreferrer" href="https://mapathonkeralam.in/" target="_blank">
            <Button className="w-100 w-40-m fl tc bg-primary white">
              <FormattedMessage {...messages.organizationContactButton} />
            </Button>
          </a>
        </p>
      </div>
    </div>
  );
}

export function WhoIsMapping() {
  const organizations = [
      { url: 'http://itmission.kerala.gov.in/', code: 'itmission', name: 'KSITM' },
      { url: 'http://www.ksdi.kerala.gov.in/ksdi/', code: 'ksdi', name: 'KSDI' },
      { url: 'https://rebuild.kerala.gov.in/en/', code: 'rki', name: 'RKI'}
  ];
  const partners = [
      { url: 'https://icfoss.in/', code: 'icfoss', name: 'ICFOSS' },
      { url: 'http://nsskerala.org/', code: 'nss', name: 'NSS' },
      { url: 'https://www.cdit.org/', code: 'cdit', name: 'CDIT' },
      { url: 'https://haritham.kerala.gov.in/', code: 'hkm', name: 'Haritha Kerala Mission' }
  ];
  return (
    <div className="cf v-mid bg-split-secondary-white">
      <div className="ph6-l ph4 pt3 pb5 white cf">
      <div className="cf fl w-100">
          <h3 className="bg-primary dib pv1 ph2 f2 fw8 tc lh-copy white ttu barlow-condensed">
            <FormattedMessage {...messages.whoIsMappingTitle} />
          </h3>
        </div>
        <div className="w-75-l w-100 fl cf">
          <p className="pr2 mb4 f5 f4-ns lh-title mw6">
            <FormattedMessage {...messages.whoIsMappingHeadline} />
          </p>
          <div className="cf pb4 pb2-l">
            {organizations.map((org, n) => (
              <div key={n} className="w-50 w-30-l fl pr5 pv1 pb4">
                <a href={org.url} rel="noopener noreferrer" target="_blank" className="link white tc ">
                  <div className={`contain org-${org.code} w-auto h3 pt5`} aria-label={org.name}>
                  <strong>{org.name}</strong>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>{contactCard()}
        <p className="pr2 pv2 mb4 f5 f4-ns lh-title mw6">
            <FormattedMessage {...messages.whoIsMappingSubTitle} />
        </p>       
        <div className="cf pb4 pb2-l pl4-l ">
            {partners.map((org, n) => (
              <div key={n} className="w-50 w-20-l fl pr5">
                <a href={org.url} rel="noopener noreferrer" target="_blank" className="link white tc ">
                  <div className={`contain org-${org.code} w-auto h3 pt5`} aria-label={org.name}>
                  <strong>{org.name}</strong>
                  </div>
                </a>
              </div>
            ))}
          </div>
        
      </div>
      
    </div>
  );
}
