import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import en from '../locales/en.json';
import ml from '../locales/ml.json';

import { setLocale } from '../store/actions/userPreferences';
import * as config from '../config';

const translatedMessages = {
  en: en,
  ml: ml,
};

/* Safari 12- and IE */
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/pt'); // Add locale data for de
  require('@formatjs/intl-pluralrules/dist/locale-data/en');
}

/* Safari 13- and IE */
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/pt'); // Add locale data for de
  require('@formatjs/intl-relativetimeformat/dist/locale-data/en');
}

// commented out the languages that we are not supporting on the first production release of TM4
const supportedLocales = [
  { value: 'en', label:  'English'},
  { value: 'ml', label: 'മലയാളം (Malayalam)' },
];

function getSupportedLocale(locale) {
  if (locale) {
    let filtered = supportedLocales.filter((i) => i.value === locale);
    if (filtered.length) {
      return filtered[0];
    }
    // if we don't have the specific language variation, return the generic locale
    filtered = supportedLocales.filter((i) => i.value === locale.substr(0, 2));
    if (filtered.length) {
      return filtered[0];
    }
  }
  return { value: 'en', label: 'English' };
}

function getTranslatedMessages(locale) {
  let localeCode = getSupportedLocale(locale);
  if (localeCode.hasOwnProperty('value')) {
    return translatedMessages[localeCode.value];
  }
  return translatedMessages[locale];
}

/* textComponent is for orderBy <select>, see codesandbox at https://github.com/facebook/react/issues/15513 */
let ConnectedIntl = (props) => {
  useEffect(() => {
    if (props.locale === null) {
      props.setLocale(getSupportedLocale(navigator.language).value);
    }
  }, [props]);
  return (
    <IntlProvider
      key={props.locale || config.DEFAULT_LOCALE}
      locale={props.locale ? props.locale.substr(0, 2) : 'en'}
      defaultLocale="en"
      textComponent={React.Fragment}
      messages={getTranslatedMessages(props.locale)}
    >
      {props.children}
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.locale,
});

ConnectedIntl = connect(mapStateToProps, { setLocale  })(ConnectedIntl);

export { ConnectedIntl, supportedLocales, getSupportedLocale, getTranslatedMessages };
