import { defineMessages } from 'react-intl';

/**
 * Internationalized messages for use on homepage.
 */
export default defineMessages({
  jumbotronTitle: {
    id: 'home.mainSection.title',
    defaultMessage: "Let's Make Our Map",
  },
  jumbotronHeadLine: {
    id: 'home.mainSection.lead',
    defaultMessage:
      "The map we are building together for mapping the resources and public assets of kerala for their efficient and creative usage as per our various needs",
  },
      secJumbotronTitle: {
    id: 'home.callToAction.title',
    defaultMessage: "We can't do it without you",
  },
  secJumbotronHeadLine: {
    id: 'home.callToAction.firstLeadLine',
    defaultMessage:
      "Anyone can contribute to the map. If you have never mapped before and would like to get started, visit our {link} page.",
  },
  secJumbotronHeadLine2: {
    id: 'home.callToAction.secondLeadLine',
    defaultMessage: 'Are you an experienced mapper? Click below to see what projects are available for mapping.',
  },
  featuredProjects: {
    id: 'home.featuredProjects.title',
    defaultMessage: 'Featured Projects',
  },
  howItWorks: {
    id: 'home.callToAction.learnLink',
    defaultMessage: 'How it works',
  },
  startButton: {
    id: 'home.mainSection.button.start',
    defaultMessage: 'Start mapping',
  },
  joinButton: {
    id: 'home.mainSection.button.join',
    defaultMessage: 'Join the community',
  },
  buildingsStats: {
    id: 'home.stats.buildings',
    defaultMessage: 'Buildings Mapped',
  },
  roadsStats: {
    id: 'home.stats.roads',
    defaultMessage: 'Mapped Roads (Km)',
  },
  editsStats: {
    id: 'home.stats.edits',
    defaultMessage: 'Total Map Edits',
  },
  communityStats: {
    id: 'home.stats.community',
    defaultMessage: 'Total Mappers',
  },
  mappersStats: {
    id: 'home.stats.mappers',
    defaultMessage: 'Mappers Online',
  },
  mappingFlowTitle: {
    id: 'home.mappingFlow.title',
    defaultMessage:
    'We can unite for rebuilding Kerala - Let us set a map that paves way for development'  },
   
    mappingFlowHeadline: {
    id: 'home.mappingFlow.headLine',
    defaultMessage:
      'Mapathon Kerala is all set to map all the public assets in Kerala, to be utilized for public needs. This initiative encourages the participation of public in mapping their localities as well as helps in developing their mapping skills. Any Keralite can be a part of this venture. It is our responsibility to mark our land on the map!',
  },
  mappingCardTitle: {
    id: 'home.mappingFlow.cards.mapping.title',
    defaultMessage: 'Mapping',
  },
  validationCardTitle: {
    id: 'home.mappingFlow.cards.validation.title',
    defaultMessage: 'Validation',
  },
  usingDataCardTitle: {
    id: 'home.mappingFlow.cards.usingData.title',
    defaultMessage: 'Utilization',
  },
  mappingCardDescription: {
    id: 'home.mappingFlow.cards.mapping.description',
    defaultMessage: 'Volunteers use satellite imagery from OpenStreetMap to trace buildings, roadways, and other features.',
  },
  validationCardDescription: {
    id: 'home.mappingFlow.cards.validation.description',
    defaultMessage:
      'Experienced volunteers check the map data to ensure it is high quality.',
  },
  usingDataCardDescription: {
    id: 'home.mappingFlow.cards.usingData.description',
    defaultMessage:
      'Humanitarian organizations use the map data to plan for disaster response and other life saving activities.',
  },
  whoIsMappingTitle: {
    id: 'home.whoIsMapping.title',
    defaultMessage: "Partners",
  },
  whoIsMappingSubTitle: {
    id: 'home.whoIsMapping.subtitle',
    defaultMessage: "in association with",
  },
  whoIsMappingHeadline: {
    id: 'home.whoIsMapping.headline',
    defaultMessage:
      'An initiative by KSITM / KSDI and Rebuild Kerala Initiative (RKI)',
  },
  organizationContactTitle: {
    id: 'home.whoIsMapping.contact.title',
    defaultMessage: 'Does your organization want to work with us?',
  },
  organizationContactButton: {
    id: 'home.whoIsMapping.contact.button',
    defaultMessage: 'Contact us',
  },
  errorLoadingTheX: {
    id: 'home.featuredProjects.error',
    defaultMessage: 'Error loading the {xWord}',
  },
  contacterTitle: {
    id: 'home.contact.contacterTitle',
    defaultMessage: 'Send us a message',
  },
  contacterName: {
    id: 'home.contact.contacterName',
    defaultMessage: 'Name',
  },
  contacterEmail: {
    id: 'home.contact.contacterEmail',
    defaultMessage: 'Email',
  },
  contacterMessage: {
    id: 'home.contact.contacterMessage',
    defaultMessage: 'Message',
  },
  contacterHeaderText: {
    id: 'home.contact.contacterHeadText',
    defaultMessage:
      'Want to discuss working with us? Or, have a question or feedback that you would like to share? Fill out the form below to contact our team.',
  },
  contacterSend: {
    id: 'home.contact.submit',
    defaultMessage: 'Send',
  },
});
