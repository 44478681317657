import React from 'react';

export class ManageIcon extends React.PureComponent {
  render() {
    return (
      <svg width="127" height="100" viewBox="0 0 127 100" {...this.props}>
        <g fillRule="nonzero" fill="none">
          <path fill="#929DB3" opacity=".28" d="M7.878 0h110v85.345h-110z" />
          <path
            d="M125.802 72.828l-3.928-.601a17.88 17.88 0 0 0-.859-2.077l2.352-3.203a1.407 1.407 0 0 0-.14-1.83l-5.343-5.345a1.41 1.41 0 0 0-1.83-.139l-3.204 2.352a17.746 17.746 0 0 0-2.077-.86l-.601-3.928A1.41 1.41 0 0 0 108.779 56h-7.558a1.41 1.41 0 0 0-1.393 1.197l-.601 3.929c-.71.242-1.406.53-2.077.859l-3.203-2.352a1.41 1.41 0 0 0-1.83.14l-5.345 5.343a1.407 1.407 0 0 0-.139 1.83l2.352 3.204a17.88 17.88 0 0 0-.86 2.077l-3.928.601A1.41 1.41 0 0 0 83 74.221v7.558c0 .695.51 1.288 1.197 1.393l3.929.601c.242.71.53 1.406.859 2.077l-2.352 3.203a1.407 1.407 0 0 0 .14 1.83l5.343 5.345a1.41 1.41 0 0 0 1.83.14l3.204-2.353c.67.33 1.366.617 2.077.86l.601 3.928c.105.69.698 1.197 1.393 1.197h7.558c.695 0 1.288-.507 1.393-1.197l.601-3.929c.71-.242 1.406-.53 2.077-.859l3.203 2.354a1.41 1.41 0 0 0 1.83-.141l5.345-5.344a1.407 1.407 0 0 0 .139-1.83l-2.352-3.204c.33-.67.617-1.366.86-2.077l3.928-.601A1.41 1.41 0 0 0 127 81.779v-7.558a1.41 1.41 0 0 0-1.198-1.393zm-11.999 5.173a8.803 8.803 0 1 1-17.606-.002 8.803 8.803 0 0 1 17.606.002z"
            fill="currentColor"
            style={{
              mixBlendMode: 'multiply',
            }}
          />
          <rect
            fill="currentColor"
            style={{
              mixBlendMode: 'multiply',
            }}
            y="15"
            width="61"
            height="10"
            rx="2"
          />
          <rect fill="#B8C0CF" x="46" y="33" width="37" height="10" rx="2" />
          <rect fill="#B8C0CF" x="61" y="51" width="22" height="10" rx="2" />
        </g>
      </svg>
    );
  }
}
